import styled from "styled-components";
import icy1 from "./images/icy1.jpg";
import icy2 from "./images/icy2.jpg";
import icy3 from "./images/icy3.jpg";

const Wrapper = styled.div`
  padding: 20px;
  font-family: "Space Mono", monospace;
  text-align: center;

  p {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 600px) {
    padding: 10px;
    h1 {
      font-size: 22px;
    }
  }
`;
const Img = styled.img`
  width: 100%;
  margin-top: 40px;
  border: 8px solid black;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    border: 4px solid black;
    margin-top: 20px;
  }
`;

function App() {
  return (
    <Wrapper>
      <h1>icy // rachel binx</h1>
      <p>photos taken in iceland, 06/23</p>
      <p style={{ fontSize: "12px" }}>
        available as 20x30" metal prints, contact rachelbinx @ gmail
      </p>
      <Img src={icy1} />
      <Img src={icy2} />
      <Img src={icy3} />
    </Wrapper>
  );
}

export default App;
